<template>
  <div v-if="$trip.places"
       class="popup-audio-guide">
    <div class="popup-audio-guide__wrapper">
      <Column class="grid-md-6 grid-lg-6"
              :full="$mobile">
        <p class="popup-audio-guide__title">Аудиогид по маршруту {{$trip.title}}</p>
        <p class="popup-audio-guide__subtitle">Прослушайте аудиогид, который подготовили наши
          путешественники</p>
        <div v-for="place in $trip.places"
             :key="place.id">
          <p class="popup-audio-trips__title">{{`${PopupAudioTrips + 1}. ${place.title}`}}</p>
          <AudioPlayer v-for="audio in place.audios"
                       class="popup-audio-guide__player"
                       :key="audio.id"
                       :params="audio"/>
        </div>
      </Column>
    </div>
  </div>
  <Loader v-else/>
</template>

<script>
export default {
  name: 'PopupAudioGuide',
};
</script>
